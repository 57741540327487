/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
// import {
//   BarChart as BarChartIcon,
//   PieChart as PieChartIcon
// } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Section {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  title: string;
}

// todo: add back sections when needed

const sections: Section[] = [
  {
    title: 'Evolution',
    icon: PetsIcon,
    href: '/evolution'
  },
  {
    title: 'Artificial Intelligence',
    icon: SchoolIcon,
    href: '/ai'
  },
  // {
  //   title: 'Dashboard',
  //   icon: PieChartIcon,
  //   href: '/app/reports/dashboard'
  // },
  // {
  //   title: 'Dashboard Alternative',
  //   icon: BarChartIcon,
  //   href: '/app/reports/dashboard-alternative'
  // },
  // {
  //   title: 'Apex Charts',
  //   icon: BarChartIcon,
  //   href: '/app/extra/charts/apex'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  // const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          <List disablePadding>
            {sections.map((section) => {
                const open = matchPath(location.pathname, {
                  path: section.href,
                  exact: false
                });

                return (<NavItem depth={1}
                        href={section.href}
                        icon={section.icon}
                        info={section.info}
                        key={section.title}
                        open={Boolean(open)}
                        title={section.title}
                />)
            })}
          </List>
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
