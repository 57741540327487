import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  // const { isAuthenticated } = useAuth();
  const isAuthenticated = true;



  if (!isAuthenticated) {

    return <Redirect to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
